import { ErpTableOptions } from '@/types/type';
import { loadPage } from '@/utils/publicMethods';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '商机编号',
      prop: 'businessNo',
      type: 'input',
      value: '',
      width: 143,
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      type: 'input',
      value: '',
      component: defineComponent({
        template: `
        <div :class="row.supplierContractNo?'record__link':''"
        @click='link(row)'
        >{{filterName(row.supplierContractNo)}}</div>
        `,
        props: ['row'],
        setup() {
          const link = (row) => {
            // 跳转到商机详情
            loadPage('OrderDetail', {
              orderId: row.orderId,
              version: row.version,
              name: 'LicenseOrderList',
            });
          };
          return { link };
        },
      }),
      width: 160,
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '口岸',
      prop: 'portName',

      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDisplay',
      type: 'input',
      value: '',
      minWidth: 120,
    },

    {
      label: '品种',
      prop: 'varietyName',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '证号',
      prop: 'certificateNumber',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '有效期',
      prop: 'validDate',
      type: 'time',
      filterProp: ['validStartDate', 'validEndDate'],
      value: [],
      minWidth: 140,
    },
    {
      label: '到港日',
      prop: 'shippingEndDate',
      type: 'time',
      filterProp: ['shippingEndStartDate', 'shippingEndEndDate'],
      value: [],
      minWidth: 140,
    },
    {
      label: '送货日期',
      prop: 'deliveryDate',
      type: 'time',
      value: [],
      filterProp: ['deliveryStartDate', 'deliveryEndDate'],
      minWidth: 140,
    },
    {
      label: '合同重量(kg)',
      prop: 'contractWeightDisplay',
      minWidth: 140,
      type: 'number', // 筛选类型 number
      filterProp: ['contractWeightMinVal', 'contractWeightMaxVal'],
      value: [],
    },
    {
      label: '实际净重(kg)',
      prop: 'actualWeightDisplay',
      type: 'number', // 筛选类型 number
      filterProp: ['actualWeightMinVal', 'actualWeightMaxVal'],
      value: [],
      minWidth: 160,
    },
  ];
  return { columnList, tableRef };
};

//
