
import { defineComponent, ref } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useOrderListData';
import useVisible from '@/hooks/useVisible';
import LicenseForm from './components/Form.vue';
import {
  erpConfirm,
  errorTip,
  getRouterParams,
  loadPage,
} from '@/utils/publicMethods';
import { httpPost } from '@/api';

export default defineComponent({
  name: 'LicenseOrderList',
  components: { ErpList, LicenseForm },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    const { visible, showVisible, closeVisible } = useVisible();
    const customParams: any = ref();
    const { id, version, data } = getRouterParams('LicenseOrderList');
    customParams.value = {
      id: id,
    };
    // 返回
    const back = () => {
      loadPage('LicenseList', {});
    };
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        back();
      }
    };

    // 编辑
    const editLicense = () => {
      showVisible();
    };
    // 删除
    const deleteLicense = () => {
      erpConfirm('确定删除吗？').then(async () => {
        const res = await httpPost('/malicrm/license/deleteLicense', {
          id: id,
          version: version,
        });
        if (res.code === 200) {
          back();
        } else {
          errorTip(res.message);
        }
      });
    };

    // 查看详情
    const rowClick = () => {
      return true;
    };

    return {
      data,
      cancel,
      closeVisible,
      back,
      editLicense,
      deleteLicense,
      customParams,
      columnList,
      tableRef,
      visible,
      showVisible,
      rowClick,
    };
  },
});
